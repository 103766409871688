import { Intent } from "@blueprintjs/core";
import { OrgRole, isOrgRoleSuperset } from "@hex/common";
import React, { useCallback, useRef } from "react";

import { useCurrentUser } from "../../../../hooks/me/useCurrentUser";
import { useOnClickProps } from "../../../../hooks/useOnClickProps.js";
import { Routes } from "../../../../route/routes";
import {
  COLLECTION_CARD_HEIGHT,
  CardContent,
  CardDescription,
  CardHeader,
  CardHeading,
  CardHeadingAnchor,
  CardMetadata,
  CardTitleDescription,
  InteractiveCard,
} from "../../../common/project-cards/StyledComponents.js";
import {
  ShowOnHover,
  ShowOnHoverContainer,
} from "../../../common/ShowOnHover.js";
import { StarIconCheckbox } from "../../../common/StarIconCheckbox.js";
import { useToaster } from "../../../common/Toasts.js";
import { useStarCollection } from "../hooks/useStarCollection.js";
import { CollectionEmoji } from "../shared/CollectionName";
import { CollectionSharingSummary } from "../shared/CollectionSharingSummary";

import { CollectionForHomeFragment } from "./CollectionsListTab2.generated";

export interface CollectionCard2Props {
  collection: CollectionForHomeFragment;
}

export const CollectionCard2: React.ComponentType<CollectionCard2Props> =
  React.memo(function CollectionCard2({ collection }: CollectionCard2Props) {
    const {
      collectionGrants,
      collectionHexLinkCountsByProjectType,
      description,
      emoji,
      id,
      name,
      starredByViewer = false,
    } = collection;

    const toaster = useToaster();
    const anchorRef = useRef<HTMLAnchorElement>(null);
    const handleStarCollectionError = useCallback(
      (isStarred) => {
        toaster.show({
          message: isStarred
            ? "There was an error removing this collection from your favorites."
            : "There was an error adding this collection to your favorites.",
          intent: Intent.DANGER,
        });
      },
      [toaster],
    );
    const handleStarIconCheckboxChange = useStarCollection(
      id,
      handleStarCollectionError,
      undefined,
    );
    const { componentCount, projectCount } =
      collectionHexLinkCountsByProjectType;

    const handleCardClick: React.MouseEventHandler = useCallback((e) => {
      if (e.metaKey) {
        window.open(anchorRef?.current?.href, "_blank");
      } else {
        anchorRef?.current?.click();
      }
    }, []);

    const stopPropagationOnClick: React.MouseEventHandler = useCallback((e) => {
      e.stopPropagation();
    }, []);

    const onCollectionCardClickProps = useOnClickProps({
      replace: false,
      to: Routes.COLLECTION.getUrl({
        collectionId: collection.id,
        collectionName: collection.name,
      }),
      onClick: stopPropagationOnClick,
    });

    const currentUser = useCurrentUser();
    const isOrgMember =
      currentUser && isOrgRoleSuperset(currentUser.orgRole, OrgRole.MEMBER);
    const containerId = `${collection.id}-collection-card-container`;

    return (
      <ShowOnHoverContainer id={containerId}>
        <InteractiveCard
          $height={COLLECTION_CARD_HEIGHT}
          aria-labelledby={`${id}-card-heading`}
          id={`${id}-card`}
          onClick={handleCardClick}
        >
          <CardContent $minimalProjectCard={false}>
            <CardTitleDescription $minimalProjectCard={false}>
              <CardHeader
                $minimalProjectCard={false}
                css={`
                  display: flex;
                  gap: 6px;
                  align-items: center;
                `}
              >
                {emoji && (
                  <CollectionEmoji $small={true}>{emoji}</CollectionEmoji>
                )}
                <CardHeading
                  $twoLineHeading={!description}
                  id={`${id}-card-heading`}
                  title={name}
                >
                  <CardHeadingAnchor
                    ref={anchorRef}
                    href={onCollectionCardClickProps.href}
                    onClick={onCollectionCardClickProps.onClick}
                  >
                    {name}
                  </CardHeadingAnchor>
                </CardHeading>

                <ShowOnHover
                  $show={starredByViewer}
                  css="display: flex;"
                  data-controller={containerId}
                >
                  <StarIconCheckbox
                    key={id}
                    id={`${id}-star-icon-checkbox`}
                    starred={starredByViewer}
                    onChange={handleStarIconCheckboxChange}
                  />
                </ShowOnHover>
              </CardHeader>
              {description ? (
                <CardDescription title={description}>
                  {description}
                </CardDescription>
              ) : null}
            </CardTitleDescription>
            <CardMetadata
              css={`
                align-items: center;
              `}
            >
              {(projectCount !== 0 || componentCount !== 0) && (
                <>
                  {projectCount !== 0 && (
                    <span>
                      {projectCount}{" "}
                      {projectCount === 1 ? "project" : "projects"}
                    </span>
                  )}
                  {componentCount !== 0 && (
                    <span>
                      {componentCount}{" "}
                      {componentCount === 1 ? "component" : "components"}
                    </span>
                  )}
                </>
              )}
              {isOrgMember && (
                <CollectionSharingSummary collectionGrants={collectionGrants} />
              )}
            </CardMetadata>
          </CardContent>
        </InteractiveCard>
      </ShowOnHoverContainer>
    );
  });
