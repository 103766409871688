import {
  CollectionHexLinksOrderByAttribute,
  CollectionOrder,
  HexOrder,
  assertNever,
} from "@hex/common";
import React from "react";

import { ViewCountDuration } from "../../../hex-list/ViewCountDuration.js";
import { SortDescIcon } from "../../../icons/CustomIcons.js";
import QuickFilterSelect from "../../shared/QuickFilterSelect.js";
import {
  HexListSortType,
  getFilterValueFromHexOrder,
} from "../../shared/SortByHexOrder.js";

export type CollectionSpecificHexOrder =
  | HexOrder
  | "COLLECTION_LINK_PROJECT_ROLE"
  | "PROJECT_ACCESS";

interface SortByCollectionOrderProps {
  collectionOrder: CollectionOrder;
  onChange: (value: CollectionOrder) => void;
}

/**
 * Transform the API Ordered attribute into a HexListSortType.
 */
export const getCollectionSortTypeFromAttribute = (
  sortByKey: CollectionHexLinksOrderByAttribute,
): HexListSortType => {
  switch (sortByKey) {
    case "LAST_FOURTEEN_DAY_VIEW_COUNT":
    case "LAST_SEVEN_DAYS_VIEW_COUNT":
    case "LAST_THIRTY_DAY_VIEW_COUNT":
    case "TOTAL_VIEW_COUNT":
      return HexListSortType.PUBLISHED_VIEWS;
    case "LAST_EDIT":
      return HexListSortType.LAST_UPDATED;
    case "CREATED_AT":
      return HexListSortType.DATE_CREATED;
    case "LAST_PUBLISH":
      return HexListSortType.RECENTLY_PUBLISHED;
    case "CATEGORY":
      return HexListSortType.CATEGORY;
    case "STATUS":
      return HexListSortType.STATUS;
    case "COLLECTION_LINK_PROJECT_ROLE":
      return HexListSortType.COLLECTION_LINK_PROJECT_ROLE;
    default:
      return HexListSortType[sortByKey];
  }
};

/**
 * Transform the current HexOrder or Collections-specific sort attribute into the HexListSortType, which
 * is used to change the current state of the sort dropdown button.
 */
export const getFilterValueFromCollectionHexOrder = (
  hexOrder: CollectionSpecificHexOrder,
): HexListSortType => {
  switch (hexOrder) {
    case "COLLECTION_LINK_PROJECT_ROLE":
      return HexListSortType.COLLECTION_LINK_PROJECT_ROLE;
    case "PROJECT_ACCESS":
      return HexListSortType.PROJECT_ACCESS;
    default:
      return getFilterValueFromHexOrder(hexOrder);
  }
};

export const getHexOrderFromCollectionSortType = (
  sortByKey: CollectionHexLinksOrderByAttribute,
): CollectionSpecificHexOrder => {
  return sortByKey;
};

export const getCollectionAttributeFromSortType = (
  sortByKey: HexListSortType,
  viewByKey: ViewCountDuration,
): CollectionHexLinksOrderByAttribute => {
  switch (sortByKey) {
    case HexListSortType.DATE_CREATED:
      return "CREATED_AT";
    case HexListSortType.RECENTLY_PUBLISHED:
      return "LAST_PUBLISH";
    case HexListSortType.LAST_UPDATED:
      return "LAST_EDIT";
    case HexListSortType.RECENTLY_VIEWED:
    case HexListSortType.PROJECT_NAME:
    case HexListSortType.OWNER:
    case HexListSortType.CREATOR:
    case HexListSortType.PROJECT_ACCESS:
    case HexListSortType.COLLECTION_LINK_PROJECT_ROLE:
    case HexListSortType.CATEGORY:
    case HexListSortType.STATUS:
    case HexListSortType.MY_ACCESS:
    case HexListSortType.ARCHIVED_AT:
    case HexListSortType.APP_ONLY_RECENTLY_VIEWED:
      return sortByKey;
    case HexListSortType.PUBLISHED_VIEWS: {
      switch (viewByKey) {
        case ViewCountDuration.ONE_WEEK:
          return "LAST_SEVEN_DAYS_VIEW_COUNT";

        case ViewCountDuration.TWO_WEEKS:
          return "LAST_FOURTEEN_DAY_VIEW_COUNT";

        case ViewCountDuration.ALL_TIME:
          return "TOTAL_VIEW_COUNT";

        case ViewCountDuration.ONE_MONTH:
          return "LAST_THIRTY_DAY_VIEW_COUNT";

        default:
          assertNever(viewByKey, viewByKey);
      }
      break;
    }
    default:
      assertNever(sortByKey, sortByKey);
  }
};

export const SortByCollectionOrder: React.ComponentType<
  SortByCollectionOrderProps
> = ({ collectionOrder, onChange }) => {
  const options = [
    { title: "Name", value: CollectionOrder.NAME },
    {
      title: "Recent additions",
      value: CollectionOrder.RECENT_ADDITION,
    },
  ];

  return (
    <QuickFilterSelect<CollectionOrder>
      buttonIcon={<SortDescIcon />}
      label="Sort by"
      options={options}
      value={collectionOrder}
      onChange={onChange}
    />
  );
};
