import { ApolloQueryResult } from "@apollo/client";
import { DOCS_LINKS, uuid } from "@hex/common";
import React, { useLayoutEffect, useRef } from "react";

import {
  HexNonIdealState,
  HexSpinner,
  HexSpinnerSizes,
} from "../../../../hex-components";
import { DocsLink } from "../../../common/DocsLink";
import { ProjectCardGrid } from "../../../common/project-cards/ProjectCardGrid.js";
import {
  HexList2InfiniteScrollContainer,
  HexList2ScrollableArea,
} from "../../../hex-list-2/HexList2InfiniteScrollContainer.js";
import {
  CollectionIcon,
  SearchIcon,
  WarningIcon,
} from "../../../icons/CustomIcons";

import { CollectionCard2 } from "./CollectionCard2.js";
import { CollectionForHomeFragment } from "./CollectionsListTab2.generated";

export interface CollectionsListProps {
  className?: string;
  collections: CollectionForHomeFragment[];
  error: boolean;
  fetchMore: () => Promise<ApolloQueryResult<any>>;
  filterApplied: boolean;
  hasMore: boolean;
  loading: boolean;
}

export const CollectionsList2: React.ComponentType<CollectionsListProps> =
  React.memo(function CollectionsList2({
    className,
    collections,
    error,
    fetchMore,
    filterApplied,
    hasMore,
    loading,
  }: CollectionsListProps) {
    const emptyState = error
      ? { icon: <WarningIcon />, title: "Error loading collections" }
      : loading
        ? { description: <HexSpinner size={HexSpinnerSizes.LARGE} /> }
        : filterApplied && collections.length === 0
          ? { icon: <SearchIcon />, title: "No collections match that filter" }
          : {
              icon: <CollectionIcon />,
              title: "No collections yet",
              description:
                "Collections give you the ability to organize and share your projects.",
              children: (
                <DocsLink to={DOCS_LINKS.Collections}>Learn more</DocsLink>
              ),
            };

    const listDivIdRef = useRef(uuid());

    useLayoutEffect(() => {
      // if we're not loading and there's more pages and we haven't
      // overflowed the list container: keep fetching more pages since
      // react-infinite-scroll will only initiate fetching on scroll
      // https://github.com/ankeetmaini/react-infinite-scroll-component/issues/286
      if (hasMore && !loading && !error) {
        const listElement = document.getElementById(listDivIdRef.current);
        if (listElement) {
          const isOverflowing =
            listElement.clientHeight < listElement.scrollHeight;

          if (!isOverflowing) {
            void fetchMore();
          }
        }
      }
    }, [error, loading, fetchMore, hasMore]);

    return (
      <HexList2ScrollableArea className={className} id={listDivIdRef.current}>
        {collections.length > 0 ? (
          <HexList2InfiniteScrollContainer
            hasMoreData={hasMore}
            length={collections.length}
            loadMoreData={fetchMore}
            scrollableTargetId={listDivIdRef.current}
          >
            <ProjectCardGrid>
              {collections.map((collection, index) => (
                <CollectionCard2
                  key={`collection-${index}`}
                  collection={collection}
                />
              ))}
            </ProjectCardGrid>
          </HexList2InfiniteScrollContainer>
        ) : (
          <HexNonIdealState {...emptyState} />
        )}
      </HexList2ScrollableArea>
    );
  });
